import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./contact-styles.css";

import PageTop from "../PageTop/PageTop";
import mail from "../../assets/icons/mail.png";
import phone from "../../assets/icons/phone.png";
import location from "../../assets/icons/location.png";

const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "Contact",
    src: "./images/contact-bg.webp",
    caption: "Contact Image",
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="ContactPage"
    >
      <PageTop pageTopInfo={pageTopInfo} />

      <div className="contact-content">
        <div className="cc-left"></div>
        <div className="cc-mid">
          <div className="contact-form">
            <form
              data-aos="fade-up"
              id="contactForm"
              action="https://smartforms.dev/submit/61af1f8e7a195017922db56f"
              method="POST"
            >
              <div className="form-top">
                <div className="de-container">
                  <label htmlFor="Name">Name</label>
                  <input
                    placeholder="Your Name"
                    id="input-name"
                    type="text"
                    name="fullname"
                    required
                  />
                </div>

                <div className="de-container">
                  <label htmlFor="Email">Email</label>
                  <input
                    id="input-email"
                    placeholder="Your Email"
                    type="email"
                    name="email address"
                    required
                  />
                </div>
              </div>

              <label htmlFor="Topic">Topic</label>
              <select name="topic" id="slct-topic" placeholder="Select Option">
                <option value="General Info">General Info</option>
                <option value="Sermons">Sermons</option>
                <option value="Services">Services</option>
                <option value="Membership">Membership</option>
                <option value="Baptism">Baptism</option>
                <option value="Ministry">Ministry</option>
                <option value="Home Groups">Home Groups</option>
                <option value="Resources">Resources</option>
                <option value="Get Involved">Get Involved</option>
                <option value="Giving">Giving</option>
                <option value="Other">Other</option>
              </select>

              <label htmlFor="Message">Message</label>
              <textarea
                placeholder="How can we help you?"
                name="message"
                id="txta-msg"
                cols="30"
                rows="10"
              ></textarea>
              <button type="submit" id="btn-sbmt">
                Submit
              </button>
            </form>
          </div>

          <div className="contact-details">
            <h3 data-aos="fade">How can we help you?</h3>
            <div data-aos="fade-right" className="line"></div>
            <p data-aos="fade" id="dscrc">
              For more info visit the church office or get in touch with us.
            </p>

            <div className="con-con">
              <div className="con-item">
                <LazyLoadImage
                  data-aos="fade"
                  className="img"
                  src={location}
                  alt="location"
                />
                <div className="con-txt">
                  <p data-aos="fade" className="con-heading">
                    Church Office
                  </p>
                  <p data-aos="fade" className="con-dscr">
                    Cnr. Fife Ave & Sam Nujoma Street,
                    <br />
                    Harare,
                    <br />
                    Zimbabwe
                  </p>
                </div>
              </div>

              <div className="con-item">
                <LazyLoadImage
                  data-aos="fade"
                  className="img"
                  src={phone}
                  alt="phone"
                />
                <div className="con-txt">
                  <p data-aos="fade" className="con-heading">
                    Phone
                  </p>
                  <p data-aos="fade" className="con-dscr">
                    +263-4-794 877
                  </p>
                </div>
              </div>

              <div className="con-item">
                <LazyLoadImage
                  data-aos="fade"
                  className="img"
                  src={mail}
                  alt="email"
                />
                <div className="con-txt">
                  <p data-aos="fade" className="con-heading">
                    Email
                  </p>
                  <p data-aos="fade" className="con-dscr">
                    onlinemedia.cbc@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cc-right"></div>
      </div>
      <div className="divider"></div>

      <div className="our-location">
        <div className="map-edges"></div>
        <div className="map-mid">
          <div className="map-mid-top">
            <h3 data-aos="fade">Location</h3>
            <div data-aos="fade-right" className="min-line"></div>
          </div>
          <div data-aos="fade" className="map-mid-bottom">
            <iframe
              title="Central Baptist Church Location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15193.52950951601!2d31.0488429!3d-17.8207011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x17aff9e57b288382!2sCentral%20Baptist%20Church%20Harare!5e0!3m2!1sen!2szw!4v1633342298622!5m2!1sen!2szw"
              width="100%"
              height={500}
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            />
          </div>
        </div>
        <div className="map-edges"></div>
      </div>
    </motion.div>
  );
};

export default Contact;
