import React, { useEffect } from "react";
import "./about-styles.css";

import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import PageTop from "../PageTop/PageTop";
import pastor from "../../assets/images/pastor.webp";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "About",
    src: "./images/about-bg.webp",
    caption: "About Image",
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="AboutPage"
    >
      <PageTop pageTopInfo={pageTopInfo} />
      <div className="wvm">
        <h3 data-aos="fade" className="who-h">
          Who We Are
        </h3>
        <div data-aos="fade-right" className="line"></div>
        <p data-aos="fade" className="wwa-description">
          We are a Gospel preaching Baptist Church, committed to Baptist
          Distinctives and General Atonement and Free Will views on salvation
          through faith in Jesus Christ on the ground of His shed blood.
        </p>
        <h3 data-aos="fade" className="vision-h">
          Vision
        </h3>
        <div data-aos="fade-right" className="line"></div>
        <p data-aos="fade" className="v-description">
          Our vision is to be Christlike in honoring God’s name, through the
          extension of His Kingdom and the performance of His will.
        </p>
        <h3 data-aos="fade" className="mission-h">
          Mission
        </h3>
        <div data-aos="fade-right" className="line"></div>
        <p data-aos="fade" className="m-description">
          Our mission is to provide a vibrant Christian community in which;
        </p>
        <ol>
          <li data-aos="fade">God is worshipped in truth and spirit</li>
          <li data-aos="fade">Christ’s Gospel is preached</li>
          <li data-aos="fade">God’s Word is taught and obeyed</li>
          <li data-aos="fade">Christian love and fellowship are practiced</li>
          <li data-aos="fade">Christ’s commission is obeyed</li>
        </ol>
        <p data-aos="fade" className="m-description">
          We therefore, express ourselves through worship as we glorify and
          magnify our sovereign God, evangelism as we witness Jesus in
          fulfillment of our God-given mission, Discipleship as we turn Christ’s
          followers in ministers and through Fellowship develop a community of
          our membership.
        </p>
      </div>

      <div className="pastor">
        <div className="pastor-left"></div>
        <div className="pastor-mid">
          <div className="pastor-mid-left">
            <LazyLoadImage
              data-aos="fade-right"
              className="img"
              src={pastor}
              alt="Pastor Asafa Makan'a"
            />
          </div>
          <div data-aos="fade-left" className="pastor-mid-right">
            <h3 data-aos="fade" className="snr-pst">
              Pastor
            </h3>
            <div data-aos="fade-left" className="min-line"></div>
            <p data-aos="fade" className="pastor-msg">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
              praesentium nulla necessitatibus? Possimus reiciendis, aspernatur
              odio sequi amet natus, id iusto repellendus nostrum ratione
              doloremque eos nam obcaecati corporis voluptatibus! <br />
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt
              tempora voluptatem nemo porro laboriosam amet? Lorem ipsum dolor,
              sit amet consectetur adipisicing elit. Facilis, sed! Veniam vero,
              repellat quaerat libero illo molestiae suscipit quam eligendi.
            </p>
          </div>
        </div>
        <div className="pastor-right"></div>
      </div>
    </motion.div>
  );
};

export default About;
