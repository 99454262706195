import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./sermons-styles.css";
import { motion } from "framer-motion";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

import PageTop from "../PageTop/PageTop";
import googlePodcasts from "../../assets/icons/google.png";

const Sermons = () => {
  const [sermon, setSermon] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "sermon");
    const getSermon = async () => {
      const data = await getDocs(usersCollectionRef);
      setSermon(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSermon();
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "Sermons",
    src: "./images/sermons-bg.webp",
    caption: "Sermons",
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="SermonsPage"
    >
      <PageTop pageTopInfo={pageTopInfo} />

      <div className="media-locations">
        <div className="ml-left"></div>
        <div className="ml-mid">
          <div className="ml-mid-left">
            <div data-aos="fade" className="ml-lh">
              Check out our live stream.
            </div>
            <div data-aos="fade-right" className="line"></div>
            <p data-aos="fade">
              Can't come in person? No worries! Check out our Sunday service
              live stream at 09:00 on YouTube.
            </p>
            <a
              href="https://www.youtube.com/c/centralbaptistchurchharare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button data-aos="fade" id="ml-yt-btn">
                Watch Online
              </button>
            </a>
          </div>
          <div className="ml-mid-right">
            <div data-aos="fade" className="ml-rh">
              Listen to past sermons.
            </div>
            <div data-aos="fade-right" className="line"></div>
            <iframe
              title="anchor.fm"
              src="https://anchor.fm/cbc-harare/embed"
              height="102px"
              width="100%"
              frameborder="0"
              scrolling="no"
              id="anchorfm"
            />
            <a
              href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83MDhhNmM1Yy9wb2RjYXN0L3Jzcw?sa=X&ved=2ahUKEwjg9cGz1aL4AhWlZvEDHfbCCxwQ9sEGegQIARAC"
              target="_blank"
              rel="noopener noreferrer"
              id="cbpod"
            >
              <div className="podcast-link-container">
                <div className="p-link">
                  <LazyLoadImage
                    data-aos="fade"
                    className="img"
                    src={googlePodcasts}
                    alt="Google Podcasts"
                  />
                  <p data-aos="fade">The Central Baptist Church Podcast</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="ml-right"></div>
      </div>

      <div className="last-week">
        <div className="lw-left"></div>
        {sermon.map((ser) => (
          <div className="lw-mid">
            <div className="lw-mid-left">
              <iframe
                data-aos="fade-right"
                width="100%"
                height="100%"
                src={ser.videoUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div data-aos="fade-left" className="lw-mid-right">
              <div data-aos="fade" className="lw-mr-h">
                This week's sermon
              </div>
              <div data-aos="fade-left" className="min-line"></div>
              <p data-aos="fade-left" className="sermon-title">
                {ser.title}
              </p>
              <p data-aos="fade-left" className="preacher">
                by {ser.preacher}
              </p>
              <p data-aos="fade-left" id="sermondate">
                {ser.sermonDate}
              </p>
              <p data-aos="fade">{ser.description}</p>
            </div>
          </div>
        ))}

        <div className="lw-right"></div>
      </div>
    </motion.div>
  );
};

export default Sermons;
