import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from "framer-motion";
import "./ministry-styles.css";

import PageTop from "../PageTop/PageTop";

const Ministry = ({ info, events }) => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "Ministries",
    src: "./images/ministry-bg.webp",
    caption: "Ministies",
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="MinistryPage"
    >
      <PageTop pageTopInfo={pageTopInfo} />

      <div className="ministry-top">
        <div className="mt-left"></div>
        <div className="mt-mid">
          <div className="mt-mid-left">
            <h4 data-aos="fade" className="ministry-h">
              {info.ministryHeading}
            </h4>
            <div data-aos="fade-right" className="line"></div>
            <p data-aos="fade" className="ministry-description">
              {info.ministryDescription}
            </p>
          </div>
          <div className="mt-mid-right">
            <h4 data-aos="fade" className="upcoming">
              Upcoming Events
            </h4>
            {events.map((event) => (
              <div className="ministry-event">
                <LazyLoadImage
                  data-aos="fade"
                  className="img"
                  src={event.image}
                  alt="Event"
                />
                <div className="event-d">
                  <p data-aos="fade">{event.description}</p>
                  <p data-aos="fade" id="event-date">
                    {event.date}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-right"></div>
      </div>

      <div className="ministry-gallary">
        <div className="mg-left"></div>
        <div className="mg-mid">
          <LazyLoadImage
            data-aos="fade-right"
            className="img"
            src={info.gallaryImg1}
            alt="Gallary1"
          />
          <LazyLoadImage
            data-aos="fade-left"
            className="img"
            src={info.gallaryImg2}
            alt="Gallary2"
          />
          <LazyLoadImage
            data-aos="fade-right"
            className="img"
            src={info.gallaryImg3}
            alt="Gallary3"
          />
          <LazyLoadImage
            data-aos="fade-left"
            className="img"
            src={info.gallaryImg4}
            alt="Gallary4"
          />
        </div>
        <div className="mg-right"></div>
      </div>
      {/*
      <div className="ministry-leader">
        <div className="mlm-left"></div>
        <div className="mlm-mid">
          <div className="mlm-mid-left">
            <LazyLoadImage
              data-aos="fade-right"
              className="img"
              src={info.ministryLeaderImg}
              alt="Ministry Leader"
            />
          </div>
          <div data-aos="fade-left" className="mlm-mid-right">
            <h3 data-aos="fade" className="mlm-h">
              {info.ministryLeaderName} /{" "}
              <span id="mlm-title">Ministry Leader</span>
            </h3>
            <p data-aos="fade" className="mlm-dscr">
              {info.ministryLeaderDescription}
            </p>
          </div>
        </div>
        <div className="mlm-right"></div>
              </div>*/}
    </motion.div>
  );
};

export default Ministry;
