import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYbxXH_jcds38oM8jc2koDVv8yUF7hH8w",
  authDomain: "cbc-website-fcbc4.firebaseapp.com",
  projectId: "cbc-website-fcbc4",
  storageBucket: "cbc-website-fcbc4.appspot.com",
  messagingSenderId: "432048682545",
  appId: "1:432048682545:web:eb54db2794410688058710",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
