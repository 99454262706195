import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./news-styles.css";
import { motion } from "framer-motion";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

import PageTop from "../PageTop/PageTop";
import download from "../../assets/icons/download.png";

const News = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [bulletin, setBulletin] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "allevents");
    const getAllEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setAllEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getAllEvents();

    Bulletin();
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "News & Events",
    src: "./images/news-bg.webp",
    caption: "News & Events",
  };

  function Bulletin() {
    const usersCollectionRef = collection(db, "bulletin");
    const getBulletin = async () => {
      const data = await getDocs(usersCollectionRef);
      setBulletin(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getBulletin();
  }

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="NewsPage"
    >
      <PageTop pageTopInfo={pageTopInfo} />
      <div className="np-bulletin">
        <div className="npb-left"></div>
        {bulletin.map((bul) => (
          <a href={bul.file_path} target="_blank" rel="noopener noreferrer">
            <div className="bulletin-container">
              <p data-aos="fade">Download latest bulletin...</p>
              <LazyLoadImage
                data-aos="fade"
                className="img"
                src={download}
                alt="Download Button"
              />
            </div>
          </a>
        ))}
        <div className="npb-right"></div>
      </div>

      {allEvents.map((eve) => (
        <div className="news-event">
          <div className="ne-left"></div>
          <div className="ne-mid">
            <div className="ne-mid-left">
              <h4 data-aos="fade">{eve.event_title}</h4>
              <div data-aos="fade-right" className="line"></div>
              <div data-aos="fade" className="event-date">
                {eve.event_date}
              </div>
              <p data-aos="fade" className="event-description">
                {eve.event_description}
              </p>
            </div>
            <div className="ne-mid-right">
              <LazyLoadImage
                data-aos="fade-left"
                className="img"
                src={eve.img}
                alt=""
              />
            </div>
          </div>
          <div className="ne-right"></div>
        </div>
      ))}
    </motion.div>
  );
};

export default News;
