import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./give-styles.css";

import PageTop from "../PageTop/PageTop";

import Aos from "aos";
import "aos/dist/aos.css";

const Give = () => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "GIVE",
    src: "./images/give-bg.webp",
    caption: "give",
  };

  return (
    <motion.div
      className="Give"
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <PageTop pageTopInfo={pageTopInfo} />

      <div className="new-container">
        <div className="nc-edges"></div>
        <div className="nc-mid">
          <h3 data-aos="fade">Your Giving Makes A Difference</h3>
          <div data-aos="fade-right" className="min-line"></div>
          <h4 id="ways">
            THERE ARE A SEVERAL WAYS TO GIVE TO CENTRAL BAPTIST CHURCH.
          </h4>

          <h4>1. IN SERVICE</h4>
          <p className="givingp" data-aos="fade">
            To give during any of our worship gatherings simply use the envelope
            provided for cash or swipe.
          </p>

          <h4>2. BANK TRANSFER</h4>
          <p className="givingp" data-aos="fade">
            See banking details below
            <br />
            <br />
            1. CBZ, Selous Ave. Branch, Account Number. 02120519510044
            <br />
            <br />
            2. CBZ, Nostro Account, Account Number. 02120519510102
          </p>

          <h4>3. ECOCASH</h4>
          <p data-aos="fade">
            See details below
            <br />
            <br />
            1. EcoCash Merchant Code Number 242907RTGS
            <br />
            <br />
          </p>

          <div className="nc-edges"></div>
        </div>
      </div>
    </motion.div>
  );
};

export default Give;
