import React from "react";
import "./preloader-styles.css";

const PreLoader = () => {
  return (
    <div className="loader">
      <p>loading...</p>
    </div>
  );
};

export default PreLoader;
