import React, { useState, useEffect } from "react";
import Ministry from "../Ministry";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

const Evangelism = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "evangelism");
    const getEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getEvents();
  }, []);

  const info = {
    ministryHeading: "Evangelism",
    ministryDescription: "Coming soon",
    gallaryImg1: "./images/ministries/evangelism/1.webp",
    gallaryImg2: "./images/ministries/evangelism/2.webp",
    gallaryImg3: "./images/ministries/evangelism/3.webp",
    gallaryImg4: "./images/ministries/evangelism/4.webp",
    ministryLeaderImg: "./images/ministries/evangelism/leader.webp",
    ministryLeaderName: "Mr. Tawanda James",
    ministryLeaderDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda laudantium ipsam ipsa earum vel culpa odit impedit cum, soluta, itaque officiis necessitatibus, omnis perferendis sint minus quibusdam! Ullam id recusandae, aliquam error sed corporis, tempora quaerat sequi exercitationem dolorum nesciunt.",
  };

  return (
    <div>
      <Ministry info={info} events={events} />
    </div>
  );
};

export default Evangelism;
