import React, { useState, useEffect } from "react";
import Ministry from "../Ministry";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

const Frequency = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "frequency");
    const getEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getEvents();
  }, []);

  const info = {
    ministryHeading: "Frequency 4.12",
    ministryDescription:
      "Frequency 4.12 is Central Baptist's christian youth group, for all young people from age 13 and above. Frequency 4.12 meets every Friday evening from 6PM to 8PM at Central Baptist Church. The mission of Frequency 4.12 is to bring young people to Christ and to prepare citizens of God's Kingdom, while it's vision is to make church a place you want to be, not a place you have to be. Frequency 4.12 seeks to have the youth step out of the mold and be exceptional for the sake of Jesus Christ. Taking the name Frequency 4.12 from 1 Timothy 4.12 - Let no man despise thy youth; but be thou an example of the believers, in word, in conversation, in charity, in spirit, in faith, in purity. It implies tuning into the frequency of 1 Timothy 4.12. Join us this Friday for worship, solid bible teaching, fun & activities and make lasting friendhips.",
    gallaryImg1: "./images/ministries/frequency/1.webp",
    gallaryImg2: "./images/ministries/frequency/2.webp",
    gallaryImg3: "./images/ministries/frequency/3.webp",
    gallaryImg4: "./images/ministries/frequency/4.webp",
    ministryLeaderImg: "./images/ministries/frequency/leader.webp",
    ministryLeaderName: "Mark Beru",
    ministryLeaderDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda laudantium ipsam ipsa earum vel culpa odit impedit cum, soluta, itaque officiis necessitatibus, omnis perferendis sint minus quibusdam! Ullam id recusandae, aliquam error sed corporis, tempora quaerat sequi exercitationem dolorum nesciunt.",
  };

  return (
    <div>
      <Ministry info={info} events={events} />
    </div>
  );
};

export default Frequency;
