import React from "react";
import { NavLink } from "react-router-dom";
import "./footer-styles.css";
import GooglePodcastIcon from "../../assets/icons/podcasts.webp";
import instaIcon from "../../assets/icons/instagram.png";
import ytIcon from "../../assets/icons/youtube.png";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="footer-top">
        <div className="top-block">
          <h4>Central Baptist Church</h4>
          <p>
            Cnr. Fife Avenue & Sam Nujoma Street
            <br /> Harare
            <br /> Zimbabwe
          </p>
          <a
            href="mailto:onlinemedia.cbc@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p id="mailto">onlinemedia.cbc@gmail.com</p>
          </a>
          <p>+263-4-794 877</p>
        </div>

        <div className="top-block">
          <h4>I'm New Here</h4>
          <NavLink to="./i'm-new-here">
            <p>Welcome to CBC</p>
          </NavLink>
          <NavLink to="./about">
            <p>About</p>
          </NavLink>
          <NavLink to="./newsandevents">
            <p>Events</p>
          </NavLink>
          <NavLink to="./contact">
            <p>Contact</p>
          </NavLink>
        </div>

        <div className="top-block">
          <h4>Resources</h4>
          <NavLink to="./newsandevents">
            <p>Bulletin</p>
          </NavLink>
          <NavLink to="./sermons">
            <p>Sermons</p>
          </NavLink>
        </div>
      </div>
      <div className="footer-btm">
        <div className="ft-line"></div>
        <div className="f-btm-content">
          <p>Copyright 2022 Central Baptist Church</p>
          <div className="social-link-footer">
            <a
              href="https://instagram.com/cbc_harare/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instaIcon} alt="Instagram" />
            </a>
            <a
              href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83MDhhNmM1Yy9wb2RjYXN0L3Jzcw?sa=X&ved=2ahUKEwjg9cGz1aL4AhWlZvEDHfbCCxwQ9sEGegQIARAC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePodcastIcon} alt="Google Podcasts" />
            </a>
            <a
              href="https://www.youtube.com/c/centralbaptistchurchharare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ytIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
