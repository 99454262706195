import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./new-styles.css";

import PageTop from "../PageTop/PageTop";

import Aos from "aos";
import "aos/dist/aos.css";

import clock from "../../assets/icons/clock.png";
import location from "../../assets/icons/location.png";
import culture from "../../assets/images/culture.webp";

const New = () => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  const pageTopInfo = {
    title: "I'M NEW HERE",
    src: "./images/new-bg.webp",
    caption: "I'm new here",
  };

  return (
    <motion.div
      className="IAmNewPage"
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <PageTop pageTopInfo={pageTopInfo} />

      <div className="new-container">
        <div className="nc-edges"></div>
        <div className="nc-mid">
          <h3 data-aos="fade">Welcome to CBC</h3>
          <div data-aos="fade-right" className="min-line"></div>
          <p data-aos="fade">
            Welcome to Central Baptist Church! We hope you feel at home and
            experiance the love of Christ. We are a gospel preaching, Bible
            based Baptist Church in the heart of Harare, Zimbabwe.
            <br />
            We care about you and have made a place for you here. Get in touch
            with us to quickly get involved in the life of the church, be it
            ministry, membership or finding the right group for you.
            <br />
          </p>
          <p data-aos="fade" className="service-times">
            Service Times
          </p>
          <div className="nc-mid-time">
            <LazyLoadImage
              data-aos="fade"
              className="img"
              src={clock}
              alt="Clock icon"
            />
            <p data-aos="fade">Sunday Service at 9AM</p>
          </div>
          <div className="nc-mid-location">
            <LazyLoadImage
              data-aos="fade"
              className="img"
              src={location}
              alt="Location icon"
            />
            <p data-aos="fade">
              Cnr. Fife Avenue & Sam Nujoma Street
              <br />
              Harare
              <br />
              Zimbabwe
            </p>
          </div>
        </div>
        <div className="nc-edges"></div>
      </div>

      <div className="what-to-expect">
        <div className="nc-edges"></div>
        <div className="wte-mid">
          <div className="wte-mid-left">
            <h3 data-aos="fade">What can I expect?</h3>
            <div data-aos="fade-right" className="line"></div>
            <p data-aos="fade">
              You can expect to be welcomed with open arms. To be quickly
              connected with a particular group that aptly suits your needs be
              it a homegroup, a youth group or either men’s or ladies
              ministries. Once you have settled in it is our hope that you would
              consider becoming part of our church family and begin to
              contribute in one of the many ministries we have available.
            </p>
            <h3 data-aos="fade">What is the culture at CBC?</h3>
            <div data-aos="fade-right" className="line"></div>
            <p data-aos="fade">
              This is a multi-cultural diverse church with members and attendees
              from a variety of countries. Being a commuter church situated in
              the centre of the central business district our aim is to
              accommodate everyone’s particular needs in order to ensure a
              fulfilling worship experience. Within our diversity we aim for a
              culture of community, togetherness and unity.
            </p>
          </div>
          <div className="wte-mid-right">
            <LazyLoadImage
              data-aos="fade-left"
              className="img"
              src={culture}
              alt="CBC Culture"
            />
          </div>
        </div>
        <div className="nc-edges"></div>
      </div>
    </motion.div>
  );
};

export default New;
