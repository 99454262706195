import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar-styles.css";

import menu from "../../assets/icons/menu.webp";
import close from "../../assets/icons/close.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = () => {
  useEffect(() => {
    handleCloseAfterClick();
  }, []);

  const [ministryClick, setMinistryClick] = useState(false);
  const [clicked, setClicked] = useState(true);

  const handleMinistryClick = () => {
    setMinistryClick(!ministryClick);
  };

  const handleMenuClick = () => {
    setClicked(!clicked);
    if (ministryClick === true) {
      setMinistryClick(false);
    }
  };

  const handleCloseAfterClick = () => {
    if (window.innerWidth < 1100) {
      setClicked(false);
      if (ministryClick === true) {
        setMinistryClick(false);
      }
    } else {
      setClicked(true);
      if (ministryClick === true) {
        setMinistryClick(false);
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 1100) {
      setClicked(true);
    } else if (window.innerWidth < 1099) {
      setClicked(false);
    }
  };

  window.addEventListener("resize", handleResize);

  return (
    <nav className="Navbar">
      <div className="left-nav">
        <Link className="logoicon" onClick={handleCloseAfterClick} to="./">
          <LazyLoadImage
            id="cbcLogo"
            src="./images/logo.png"
            alt="Central Baptist Church Logo"
          />
        </Link>
        <h4 className="logo">
          <Link className="noNSH" onClick={handleCloseAfterClick} to="./">
            CENTRAL BAPTIST CHURCH
          </Link>
        </h4>
      </div>
      <div className="right-nav">
        <img
          id="menu"
          onClick={handleMenuClick}
          src={clicked ? close : menu}
          alt="Menu"
        />
        <ul
          className="nav-items"
          style={{ transform: clicked ? "" : "translateX(100%)" }}
        >
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./home"
            >
              HOME
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./about"
            >
              ABOUT
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./sermons"
            >
              SERMONS
            </NavLink>
          </li>
          <li onClick={handleMinistryClick} className="nav-item">
            MINISTRIES
            <ul
              className={
                ministryClick ? "ministries-drop-down" : "hide-ministries"
              }
            >
              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./frequency-412"
              >
                FREQUENCY 4.12
              </NavLink>

              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./sunday-school"
              >
                SUNDAY SCHOOL
              </NavLink>
              {/* 
              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./family-ministry"
              >
                FAMILY MINISTRY
              </NavLink>

              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./home-group-ministry"
              >
                HOME GROUP MINISTRY
              </NavLink>
                  */}
              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./ladies-fellowship"
              >
                LADIES FELLOWSHIP
              </NavLink>
              {/* 
              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./mens-fellowship"
              >
                MENS FELLOWSHIP
              </NavLink>

              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./social-concerns"
              >
                SOCIAL CONCERNS
              </NavLink>

              <NavLink
                className="subi"
                onClick={handleCloseAfterClick}
                to="./evangelism"
              >
                EVANGELISM
              </NavLink>
              */}
            </ul>
          </li>
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./newsandevents"
            >
              NEWS & EVENTS
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./i'm-new-here"
            >
              I'M NEW HERE
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./contact"
            >
              CONTACT
            </NavLink>
          </li>
          <li id="give" className="nav-item">
            <NavLink
              className="noNS"
              onClick={handleCloseAfterClick}
              to="./give"
            >
              GIVE
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
