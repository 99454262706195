import React, { useState, useEffect } from "react";
import Ministry from "../Ministry";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

const SundaySchool = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "sundayschool");
    const getEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getEvents();
  }, []);

  const info = {
    ministryHeading: "Sunday School",
    ministryDescription:
      "Children meet for Sunday school lessons during the Sunday morning services. Using material developed for Southern Africa the children are taken through lessons from different Bible stories, giving them a solid foundation in God's word and preparing them for full service sermons.",
    gallaryImg1: "./images/ministries/sundayschool/1.webp",
    gallaryImg2: "./images/ministries/sundayschool/2.webp",
    gallaryImg3: "./images/ministries/sundayschool/3.webp",
    gallaryImg4: "./images/ministries/sundayschool/4.webp",
    ministryLeaderImg: "./images/ministries/sundayschool/leader.webp",
    ministryLeaderName: "Mr. Tawanda James",
    ministryLeaderDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda laudantium ipsam ipsa earum vel culpa odit impedit cum, soluta, itaque officiis necessitatibus, omnis perferendis sint minus quibusdam! Ullam id recusandae, aliquam error sed corporis, tempora quaerat sequi exercitationem dolorum nesciunt.",
  };

  return (
    <div>
      <Ministry info={info} events={events} />
    </div>
  );
};

export default SundaySchool;
