import React, { useState, useEffect } from "react";
import Ministry from "../Ministry";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

const Family = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "family");
    const getEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getEvents();
  }, []);

  const info = {
    ministryHeading: "Family Ministry",
    ministryDescription:
      "The family is central to the healthy functioning of the church and indeed the community. The CBC Family Ministry committee has facilitated family growth through various marriage and parenting enrichment programs over the years. At times family oriented activities have been organized as well. These have greatly benefited those who participated. The ministry is run by a committee that organizes events such as Family breakfast, Family Fun Day.  Family Picnic Day, Family Movie Nights, Parenting Seminars & Couples Conferences",
    gallaryImg1: "./images/ministries/family/1.webp",
    gallaryImg2: "./images/ministries/family/2.webp",
    gallaryImg3: "./images/ministries/family/3.webp",
    gallaryImg4: "./images/ministries/family/4.webp",
    ministryLeaderImg: "./images/ministries/family/leader.webp",
    ministryLeaderName: "Mr. Tawanda James",
    ministryLeaderDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda laudantium ipsam ipsa earum vel culpa odit impedit cum, soluta, itaque officiis necessitatibus, omnis perferendis sint minus quibusdam! Ullam id recusandae, aliquam error sed corporis, tempora quaerat sequi exercitationem dolorum nesciunt.",
  };

  return (
    <div>
      <Ministry info={info} events={events} />
    </div>
  );
};

export default Family;
