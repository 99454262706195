import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ScrollToTop from "./ScrollToTop";
import PreLoader from "./components/PreLoader/PreLoader";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Sermons from "./components/Sermons/Sermons";
import News from "./components/News/News";
import New from "./components/New/New";
import Contact from "./components/Contact/Contact";
import Frequency from "./components/Ministries/Frequency/Frequency";
import Evangelism from "./components/Ministries/Evangelism/Evangelism";
import Family from "./components/Ministries/Family/Family";
import HomeGroup from "./components/Ministries/HomeGroup/HomeGroup";
import Ladies from "./components/Ministries/Ladies/Ladies";
import Men from "./components/Ministries/Men/Men";
import SocialConcerns from "./components/Ministries/SocialConcerns/SocialConcerns";
import SundaySchool from "./components/Ministries/SundaySchool/SundaySchool";
import Footer from "./components/Footer/Footer";
import Give from "./components/Give/Give";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <PreLoader />
      ) : (
        <Router>
          <Navbar />
          <ScrollToTop />/
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Route exact path="/home">
                <Home />
              </Route>

              <Route exact path="/about">
                <About />
              </Route>

              <Route exact path="/sermons">
                <Sermons />
              </Route>

              <Route exact path="/newsandevents">
                <News />
              </Route>

              <Route exact path="/evangelism">
                <Evangelism />
              </Route>

              <Route exact path="/family-ministry">
                <Family />
              </Route>

              <Route exact path="/home-group-ministry">
                <HomeGroup />
              </Route>

              <Route exact path="/ladies-fellowship">
                <Ladies />
              </Route>

              <Route exact path="/mens-fellowship">
                <Men />
              </Route>

              <Route exact path="/social-concerns">
                <SocialConcerns />
              </Route>

              <Route exact path="/sunday-school">
                <SundaySchool />
              </Route>

              <Route exact path="/frequency-412">
                <Frequency />
              </Route>

              <Route exact path="/i'm-new-here">
                <New />
              </Route>

              <Route exact path="/contact">
                <Contact />
              </Route>

              <Route exact path="/give">
                <Give />
              </Route>
            </Switch>
          </AnimatePresence>
          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
