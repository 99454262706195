import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "./page-top-styles.css";

const PageTop = ({ pageTopInfo }) => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  return (
    <div className="PageTop">
      <h1 data-aos="fade-right">{pageTopInfo.title}</h1>
      <LazyLoadImage
        className="img"
        src={pageTopInfo.src}
        alt={pageTopInfo.caption}
      />
    </div>
  );
};

export default PageTop;
