import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./home-styles.css";

import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";

import GooglePIcon from "../../assets/icons/podcasts.webp";
import instaIcon from "../../assets/icons/instagram.png";
import ytIcon from "../../assets/icons/youtube.png";
import downArrow from "../../assets/icons/down-arrow.png";
import homeMid from "../../assets/images/home-mid.webp";
import clock from "../../assets/icons/clock.png";
import location from "../../assets/icons/location.png";

import wwa from "../../assets/images/wwa.webp";
import frequency from "../../assets/images/frequency.webp";
import ladies from "../../assets/images/ladies.webp";
import sunday from "../../assets/images/sunday.webp";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 800, easing: "ease", delay: 0 });
  }, []);

  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      className="Home-Page"
    >
      <div className="home-top">
        <div className="left-edge"></div>
        <div className="hero">
          <div className="hero-content">
            <h1 className="hero-h">WELCOME TO CENTRAL BAPTIST CHURCH</h1>
            <p className="tag-line">
              To be Christ Like in honouring God’s name, extending His kingdom
              and performing His will.
            </p>
            <section className="hero-btn-container">
              <a
                href="https://www.youtube.com/c/centralbaptistchurchharare"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="watch-online-btn">Live Stream</button>
              </a>
              <Link to="./i'm-new-here">
                <button className="new-btn">I'm New Here</button>
              </Link>
            </section>
            <motion.div
              className="bottom-hero"
              animate={{ y: [0, 30, 0, 30, 0] }}
              transition={{
                type: "spring",
                duration: 5,
                bounce: 0.6,
                repeat: Infinity,
              }}
            >
              <LazyLoadImage
                className="img"
                id="d-arrow"
                src={downArrow}
                alt="Down Arrow"
              />
            </motion.div>
          </div>
        </div>
        <div className="right-edge">
          <div className="socialblock">
            <a
              href="https://instagram.com/cbc_harare/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage className="img" src={instaIcon} alt="Instagram" />
            </a>
            <a
              href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83MDhhNmM1Yy9wb2RjYXN0L3Jzcw?sa=X&ved=2ahUKEwjg9cGz1aL4AhWlZvEDHfbCCxwQ9sEGegQIARAC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage className="img" src={GooglePIcon} alt="Anchor" />
            </a>
            <a
              href="https://www.youtube.com/c/centralbaptistchurchharare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage className="img" src={ytIcon} alt="YouTube" />
            </a>
          </div>
        </div>
      </div>

      <div className="home-mid">
        <div className="mid-l-edge"></div>
        <div className="mid-mid">
          <div className="mid-mid-left">
            <h3 data-aos="fade">Join us this Sunday</h3>
            <div data-aos="fade" className="line"></div>
            <p data-aos="fade">
              Join us online OR mask up and join us in person for the Sunday
              service.
            </p>
            <div className="mid-time">
              <LazyLoadImage
                data-aos="fade"
                className="img"
                src={clock}
                alt="Clock icon"
              />
              <p data-aos="fade">Sunday Service at 9AM</p>
            </div>
            <div className="mid-location">
              <LazyLoadImage
                data-aos="fade"
                className="img"
                src={location}
                alt="Location icon"
              />
              <p data-aos="fade">
                Cnr. Fife Avenue & Sam Nujoma Street
                <br />
                Harare
                <br />
                Zimbabwe
              </p>
            </div>
            <a
              href="https://www.youtube.com/c/centralbaptistchurchharare"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button data-aos="fade" id="ytc-btn">
                Watch Online
              </button>
            </a>
          </div>
          <div className="mid-mid-right">
            <LazyLoadImage
              data-aos="fade-left"
              className="img"
              src={homeMid}
              alt="People Talking"
            />
          </div>
        </div>
      </div>

      <div className="who-we-are">
        <div className="wwa-l-edge"></div>
        <div className="wwa-mid-section">
          <div data-aos="fade" className="wwa-mid-left">
            <h3 data-aos="fade">Who We Are</h3>
            <div data-aos="fade-right" className="wwa-line"></div>
            <p data-aos="fade">
              We are a gospel preaching baptist church, committed to baptist
              distinctives and general atonement and free will views on
              salvation through faith in Jesus Christ on the ground of His shed
              blood.
              <br />
              <br />
              Our vision is to be Christlike in honoring God’s name, through the
              extension of His Kingdom and the performance of His will.
              <br />
              <br />
              We therefore, express ourselves through worship as we glorify and
              magnify our sovereign God, evangelism as we witness Jesus in
              fulfillment of our God-given mission, discipleship as we turn
              Christ’s followers in ministers and through fellowship develop a
              community of our membership.
            </p>
            <Link data-aos="fade" id="lm-btn" to="./about">
              Learn More
            </Link>
          </div>
          <div className="wwa-img">
            <LazyLoadImage
              data-aos="fade-left"
              className="img"
              src={wwa}
              alt="Central Bpatist Church"
            />
          </div>
        </div>
      </div>

      <div className="home-ministries">
        <div className="hm-heading">
          <h3 data-aos="fade">Ministries</h3>
          <div data-aos="fade-right" className="min-line"></div>
        </div>
        <div data-aos="fade" className="ministry-tabs-container">
          <div className="mtc-left"></div>
          <div className="mtc-mid">
            <Link to="./frequency-412">
              <div className="ministry-container">
                <h4>Frequency 4.12</h4>
                <LazyLoadImage
                  className="img"
                  src={frequency}
                  alt="Frequency 4.12"
                />
              </div>
            </Link>
            <Link to="./sunday-school">
              <div className="ministry-container">
                <h4>Sunday School</h4>
                <LazyLoadImage
                  className="img"
                  src={sunday}
                  alt="Sunday School"
                />
              </div>
            </Link>
            {/*  
            <Link to="./family-ministry">
              <div className="ministry-container">
                <h4>Family Ministry</h4>
                <LazyLoadImage
                  className="img"
                  src={family}
                  alt="Family Ministry"
                />
              </div>
            </Link>
            <Link to="./home-group-ministry">
              <div className="ministry-container">
                <h4>Home Group Ministry</h4>
                <LazyLoadImage
                  className="img"
                  src={home}
                  alt="Home Group Ministry"
                />
              </div>
            </Link>*/}
            <Link to="./ladies-fellowship">
              <div className="ministry-container">
                <h4>Ladies' Fellowship</h4>
                <LazyLoadImage
                  className="img"
                  src={ladies}
                  alt="Ladies' Fellowship"
                />
              </div>
            </Link>
            {/* 
            <Link to="./mens-fellowship">
              <div className="ministry-container">
                <h4>Men's Fellowship</h4>
                <LazyLoadImage
                  className="img"
                  src={men}
                  alt="Men's Fellowship"
                />
              </div>
            </Link>
            <Link to="./social-concerns">
              <div className="ministry-container">
                <h4>Social Concerns</h4>
                <LazyLoadImage
                  className="img"
                  src={social}
                  alt="Social Concerns"
                />
              </div>
            </Link>
            <Link to="./evangelism">
              <div className="ministry-container">
                <h4>Evangelism</h4>
                <LazyLoadImage className="img" src={evan} alt="Evangelism" />
              </div>
            </Link>*/}
          </div>
          <div className="mtc-right"></div>
        </div>
      </div>

      <div className="church-program">
        <div className="cp-left"></div>
        <div className="cp-mid">
          <div className="cp-mid-top">
            <h3 data-aos="fade">Church Program</h3>
            <div data-aos="fade-right" className="min-line"></div>
          </div>
          <div data-aos="fade" className="cp-mid-btm">
            <div className="program-container">
              <p className="cp-item-h">Services</p>
              <p id="p-times">Sunday: 09:00</p>
            </div>

            <div className="program-container">
              <p className="cp-item-h">Prayer</p>
              <p id="p-times">Sunday: 07:30</p>
            </div>

            <div className="program-container">
              <p className="cp-item-h">Home Groups</p>
              <p id="p-times">Thursday: 19:00</p>
            </div>

            <div className="program-container">
              <p className="cp-item-h">Friday Prayer Fellowship</p>
              <p id="p-times">First Friday of the month: 17:30</p>
            </div>

            <div className="program-container">
              <p className="cp-item-h">Frequency 4.12</p>
              <p id="p-times">Friday: 18:00</p>
            </div>
          </div>
        </div>
        <div className="cp-right"></div>
      </div>

      <div className="our-location">
        <div className="map-edges"></div>
        <div className="map-mid">
          <div className="map-mid-top">
            <h3 data-aos="fade">Location</h3>
            <div data-aos="fade-right" className="min-line"></div>
          </div>
          <div data-aos="fade" className="map-mid-bottom">
            <iframe
              title="CBC Location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15193.52950951601!2d31.0488429!3d-17.8207011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x17aff9e57b288382!2sCentral%20Baptist%20Church%20Harare!5e0!3m2!1sen!2szw!4v1633342298622!5m2!1sen!2szw"
              width="100%"
              height={500}
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            />
          </div>
        </div>

        <div className="map-edges"></div>
      </div>
    </motion.div>
  );
};

export default Home;
