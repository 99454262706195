import React, { useState, useEffect } from "react";
import Ministry from "../Ministry";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";

const Ladies = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "ladies");
    const getEvents = async () => {
      const data = await getDocs(usersCollectionRef);
      setEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getEvents();
  }, []);

  const info = {
    ministryHeading: "Ladies Fellowship",
    ministryDescription:
      "The ladies fellowship exists to glorify God  in our homes, church and community through prayer, bible teaching, witnessing, service and care. We meet once a month on the second Saturday of the month. Married women and single women of all categories are welcome.  We fellowship over a cup of tea, special lunches, and ladies breakfasts. We equip each other in good works, homemaking skills as well as praying for another. Please come join us!",
    gallaryImg1: "./images/ministries/ladies/1.webp",
    gallaryImg2: "./images/ministries/ladies/2.webp",
    gallaryImg3: "./images/ministries/ladies/3.webp",
    gallaryImg4: "./images/ministries/ladies/4.webp",
    ministryLeaderImg: "./images/ministries/ladies/leader.webp",
    ministryLeaderName: "Mrs. Sophia Makan'a",
    ministryLeaderDescription:
      "The ladies fellowship exists to glorify God  in our homes, church and community through prayer, bible teaching, witnessing, service and care. We meet once a month on the second Saturday of the month. Married women and single women of all categories are welcome.  We fellowship over a cup of tea, special lunches, and ladies breakfasts. We equip each other in good works, homemaking skills as well as praying for another. Please come join us!",
  };

  return (
    <div>
      <Ministry info={info} events={events} />
    </div>
  );
};

export default Ladies;
